const setData = (data) => {
  // Retrieves the user token from localStorage
  var dataLocal = localStorage.getItem("payment-flow-persist");
  dataLocal = dataLocal ? JSON.parse(dataLocal) : {};
  dataLocal = { ...dataLocal, ...data };
  return localStorage.setItem(
    "payment-flow-persist",
    JSON.stringify(dataLocal)
  );
};
const setParamData = (data) => {
  // Retrieves the user token from localStorage
  var dataLocal = localStorage.getItem("param-data");
  dataLocal = dataLocal ? JSON.parse(dataLocal) : {};
  dataLocal = { ...dataLocal, ...data };
  return localStorage.setItem("param-data", JSON.stringify(dataLocal));
};
const removeParamData = (item) => {
  // Retrieves the user token from localStorage
  var dataLocal = localStorage.getItem("param-data");
  dataLocal = dataLocal ? JSON.parse(dataLocal) : {};
  delete dataLocal[item];
  dataLocal = { ...dataLocal };
  return localStorage.setItem("param-data", JSON.stringify(dataLocal));
};
const getParamData = (data) => {
  // Retrieves the user token from localStorage
  var dataLocal = localStorage.getItem("param-data");
  dataLocal = dataLocal ? JSON.parse(dataLocal) : {};
  return dataLocal;
};
const getData = (data) => {
  // Retrieves the user token from localStorage
  var dataLocal = localStorage.getItem("payment-flow-persist");
  dataLocal = dataLocal ? JSON.parse(dataLocal) : {};
  return dataLocal;
};

const setTransactionData = (data) => {
  var dataLocal = localStorage.getItem("transaction_data");
  dataLocal = dataLocal ? JSON.parse(dataLocal) : {};
  dataLocal = { ...dataLocal, ...data };
  return localStorage.setItem("transaction_data", JSON.stringify(dataLocal));
};

const getTransactionData = (data) => {
  // Retrieves the user token from localStorage
  var dataLocal = localStorage.getItem("transaction_data");
  dataLocal = dataLocal ? JSON.parse(dataLocal) : {};
  return dataLocal;
};

const removeTransactionData = (item) => {
  // Retrieves the user token from localStorage
  var dataLocal = localStorage.getItem("transaction_data");
  dataLocal = dataLocal ? JSON.parse(dataLocal) : {};
  delete dataLocal[item];
  dataLocal = { ...dataLocal };
  return localStorage.setItem(
    "transaction_data",
    JSON.stringify(dataLocal)
  );
};

const removeData = (item) => {
  // Retrieves the user token from localStorage
  var dataLocal = localStorage.getItem("payment-flow-persist");
  dataLocal = dataLocal ? JSON.parse(dataLocal) : {};
  delete dataLocal[item];
  dataLocal = { ...dataLocal };
  return localStorage.setItem(
    "payment-flow-persist",
    JSON.stringify(dataLocal)
  );
};

const setTxnData = (data) => {
  // Retrieves the user token from localStorage
  var dataLocal = localStorage.getItem("txnData");
  dataLocal = dataLocal ? JSON.parse(dataLocal) : {};
  dataLocal = { ...dataLocal, ...data };
  return localStorage.setItem("txnData", JSON.stringify(dataLocal));
};

export {
  getData,
  setData,
  removeData,
  setTransactionData,
  getTransactionData,
  setParamData,
  removeParamData,
  getParamData,
  setTxnData,
  removeTransactionData,
};
