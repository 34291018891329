import "./style.css";
import "./index.css";
import "./assets/scss/styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";

let render = () => {
  import("./assets/scss/styles.scss").then((x) => {
    require("./AppRender");
  });
};
render();
