import { getStates, getCountries } from "../api-calls/auth";
import React, { createContext, useEffect, useState } from "react";

export const UserContext = createContext();

function UserContextProvider(props) {
  const [States, setStates] = useState([]);
  const [Countries, setCountries] = useState([]);

  useEffect(() => {
    fetchStates();
    fetchCountries();
  }, []);

  const fetchStates = async () => {
    const response = await getStates();
    if (response.meta.code === 200) {
      setStates(response.data);
    }
  };
  const fetchCountries = async () => {
    const response = await getCountries();
    if (response.meta.code === 200) {
      setCountries(response.Data);
    }
  };

  return (
    <UserContext.Provider
      value={{
        States: States,
        Countries: Countries,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}

export default UserContextProvider;
