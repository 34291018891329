/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-sub-hidden menu-hidden menu-sub-hidden";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English - LTR", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" },
  { id: "enrtl", name: "English - RTL", direction: "rtl" },
];

// export const firebaseConfig = {
//   apiKey: "AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg",
//   authDomain: "gogo-react-login.firebaseapp.com",
//   databaseURL: "https://gogo-react-login.firebaseio.com",
//   projectId: "gogo-react-login",
//   storageBucket: "gogo-react-login.appspot.com",
//   messagingSenderId: "216495999563"
// };

export const searchPath = "/app/pages/search";
export const servicePath = "https://api.coloredstrategies.com";

//******************************************************************Live Path ******************************************************************************//

export const localPath = "https://portal.leverageedu.com/api/";
export const services_api = "https://leverageedu.com/services/one-view-portal/"; // System
export const redirectToAccountLogin =
  "https://leverageedu.com/account/user/login";
export const meetingUrl = "https://leverageedu.com/app/"; // Meeting Url
export const liveClasses = "https://leverageedu.com/services/live-classes/";
// export const info_platform_api = "https://leverageedu.com/services/platform/";
export const accountPath = "https://leverageedu.com/services/account/";
export const master_api = "https://leverageedu.com/services/masters/";
// export const integrationPath = "https://leverageedu.com/services/integrations/";
export const univBasePath = "https://leverageedu.com/universities/";
// export const financePath = "https://leveragefinance.co/api/v2/"; // Meeting Url
export const studyBasePath = "https://leverageedu.com/study-locations/";
export const ebookBasePath = "https:/leverageedu.com/ebooks/";
export const market_place_api = "https://leverageedu.com/services/marketplace/";
export const leverageIp = "https://fly.finance/forex"; //https://mail.google.com/mail/u/0/#search/pod/FMfcgzGljvNMxzzXwpmNKcQdclzbTrQc
export const moneyhopPayURl = "https://pay.moneyhop.co";

export const info_platform_api = process.env.REACT_APP_INFO_PLATFORM_API;
export const integrationPath = process.env.REACT_APP_INTEGRATION_PATH;
export const financePath = process.env.REACT_APP_FINANCE_PATH;
export const financePathV1 = process.env.REACT_APP_FINANCE_PATH_V1;
export const ebixUrl = process.env.REACT_APP_EBIXURL;
//******************************************************************UAT Path ******************************************************************************//
// export const localPath = "https://apiuat.leverageedu.com/portal/api/";
// export const services_api =
//   "https://pat.leverageedu.com/services/one-view-portal/"; // System
// export const redirectToAccountLogin = "https://financeuat.leverageedu.com/";
// export const meetingUrl = "https://pat.leverageedu.com/app/"; // Meeting Url
// export const liveClasses = "https://apiuat.leverageedu.com/liveclasses/";
// export const info_platform_api = "https://apiuat.leverageedu.com/ip/";
// export const accountPath = "https://apiuat.leverageedu.com/account/";
// export const master_api = "https://pat.leverageedu.com/services/masters/";
// export const integrationPath = "https://apiuat.leverageedu.com/integrations/";
// export const financePath = "https://apiuat.leverageedu.com/lf/v2/"; // Meeting UrlA
// export const univBasePath = "https://pat.leverageedu.com/universities/";
// export const studyBasePath = "https://pat.leverageedu.com/study-locations/";
// export const leverageIp = "https://apiuat.leverageedu.com/lf/";
// export const ebookBasePath = "https://pat.leverageedu.com/ebooks/";
// export const market_place_api = "https://apiuat.leverageedu.com/marketplace/";
// export const moneyhopPayURl = "https://pay.moneyhop.co";

// token = eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7ImlzX3ByZW1pdW0iOjAsImlzX21vYmlsZV92ZXJpZmllZCI6MSwidXNlcl9pZCI6IjNhY2EwODMwLWQzOGItMTFlYi1hYjg5LTBhOWI0NTdkZTZiMCIsInBob3RvIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUFUWEFKei1xYVVIRzMyNGdzaktubjR6SnNBWE9zQmxsVWlfWjZ5ZHVfay09czk2LWMiLCJ1c2VyX2ZuYW1lIjoiVmFuZWV0IiwidXNlcl9sbmFtZSI6IkthcG9vciIsImVtYWlsIjoidmFuZWV0LmthcG9vckBsZXZlcmFnZWVkdS5jb20iLCJyb2xlIjoiU3R1ZGVudCIsInByZXBhcmluZ19mb3IiOiIiLCJjdXJyZW50X2VkdWNhdGlvbiI6IiIsIm1vYmlsZSI6IjczMDM0Mzc0ODUiLCJidXNpbmVzc191bml0IjpbbnVsbF19LCJpYXQiOjE2MjUyMzkzMjZ9.GjG86Pjl0N770Bun1dQGmsEz58rqvkC30TvFwd38lFc

// ****************************************************Extra*************************************************************************************************
// export const integrationsPath = "https://leverageedu.com/services/integrations/";
// export const live_classes_api_test = "https://leverageedu.com/services/live-classes/";
// export const system_services_api = "https://leverageedu.com/services/system/"; // System

//******************************************************************UAT Path ******************************************************************************//
// export const services_api = "https://pat.leverageedu.com/services/one-view-portal/"; // System

//******************************************************************Local Path ******************************************************************************//
// export const accountPath = "http://localhost:8010/";
// export const liveClasses = "http://localhost:3900/";
// export const meetingUrl = "http://localhost:3001/"; // Meeting Url
// export const financePath = "http://localhost:4001/v1/"; // Meeting Url
// export const leverageIp = "http://localhost:3000";
// export const redirectToAccountLogin = "http://localhost:3000/";
// export const financePath = "http://localhost:4001/v1/"; // Meeting Url

// export const services_api = "http://localhost:4002/"; //main
// export const accountPath = "http://localhost:4003/";

// export const localPath = "http://localhost:4001/"; //main
//************************************************************************* login route************************************************************************************ *//
/*
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/

// xxxxxxxxxxxxxxxxx- STATIC URLS - xxxxxxxxxxxxxxxxxxxxxxx
export const essay_flow = 1;
export const s3BaseUrl = "https://s3.ap-south-1.amazonaws.com/leverageedu/";
export const s3LeverageEduNew = "https://images.leverageedu.com/";
export const school_logo_path =
  "https://s3.ap-south-1.amazonaws.com/leverageedu/school-logo/";
export const study_location_path =
  "https://s3.ap-south-1.amazonaws.com/leverageedu/study-master/";

export const themeColorStorageKey = "__theme_color";
export const isMultiColorActive = true;
export const defaultColor = "light.blue";
export const isDarkSwitchActive = true;
export const defaultDirection = "ltr";
export const themeRadiusStorageKey = "__theme_radius";
export const isDemo = false;
export const AES_KEY =
  "Zr4u7x!A%D*G-KaPdSgUkXp2s5v8y/B?E(H+MbQeThWmYq3t6w9z$C&F)J@NcRfU";
export const GOOGLE_KEY = "AIzaSyBdjo2f1Jt9PH2kfVRak1gJAqCvzbzkIqE";

// xxxxxxxxxxxxxxxxx- FEATURE FLAGS - xxxxxxxxxxxxxxxxxxxxxxx

export const CF_APP = 1;
