import * as Sentry from "@sentry/react";
import React, { Suspense } from "react";
import UserContextProvider from "./context";
import { createRoot } from "react-dom/client";
import * as serviceWorker from "./serviceWorker";

const App = React.lazy(() => import(/* webpackChunkName: "App" */ "./App"));

Sentry.init({
  dsn: "https://6f1ddc9e6ad24806906f78ca2602f5a6@o422359.ingest.sentry.io/5663203",
});

const root = createRoot(document.getElementById("root"));

root.render(
  <UserContextProvider>
    <Suspense fallback={<div className="loading" />}>
      <App />
    </Suspense>
  </UserContextProvider>
);
serviceWorker.unregister();
