import decode from "jwt-decode";
import { AES_KEY } from "../../constants/defaultValues";

var CryptoJS = require("crypto-js");
const loggedIn = () => {
  if (localStorage.getItem("token")) {
    return true;
  } else {
    return false;
  }
  // Checks if there is a saved token and it's still valid
  // const token = getToken(); // GEtting token from localstorage
  // return !!token && !isTokenExpired(token); // handwaiving here
};

// const isTokenExpired = token => {
//   try {
//     const decoded = decode(token);
//     // if (decoded.exp < Date.now() / 1000) {
//     if (decoded) {
//       // Checking if token is expired. N
//       return true;
//     } else return false;
//   } catch (err) {
//     return false;
//   }
// };

const getToken = () => {
  // Retrieves the user token from localStorage
  return localStorage.getItem("token");
};

const logout = () => {
  // Clear user token and profile data from localStorage
  localStorage.removeItem("token");
};

const getProfile = () => {
  return decode(getToken());
};

const getRole = (id) => {
  const data = {
    1: "Super Admin",
    2: "Manager",
    3: "Head Coach",
    4: "Sales POC",
    5: "KAM",
    20: "Admin",
  };
  return data[id];
};

const encryptData = (text) => {
  var ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(text),
    AES_KEY
  ).toString();
  return ciphertext;
};

const decryptData = (text) => {
  try {
    var bytes = CryptoJS.AES.decrypt(text, AES_KEY);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (e) {
    return { isInvalid: true };
  }
};

// encrypted string without containing special characters
const encryptDataURL = (text) => {
  var ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(text),
    AES_KEY
  ).toString();
  return ciphertext
    .replace(/\+/g, "aaaaa")
    .replace(/\//g, "bbbbb")
    .replace(/\=/g, "ccccc");
};

const decryptDataURL = (text) => {
  try {
    var bytes = CryptoJS.AES.decrypt(
      text.replace(/aaaaa/g, "+").replace(/bbbbb/g, "/").replace(/ccccc/g, "="),
      AES_KEY
    );
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (e) {
    return { isInvalid: true };
  }
};

export {
  getProfile,
  loggedIn,
  logout,
  getRole,
  encryptData,
  decryptData,
  decryptDataURL,
  encryptDataURL,
  getToken,
};
