export const insertLead = "insert-lead";
export const loginUser = "user/loginUser";
export const getBankList = "pay/getBankList";
export const getDocuments = "user/getDocument";
export const getCountriesList = "getCountries";
export const sendAdharOtp = "document/ocrVerification"; //"user/sendAdharOtp";
export const getRecepient = "user/getRecepient";
export const addRecepient = "user/addRecepient";
export const registerUser = "user/registerUser";
export const sendMobileOtp = "pay/sendMobileOtp";
export const getTransactions = "transaction/get";
export const getUserProfile = "pay/getUserProfile";
export const uploadDocument = "document/uploadKyc"; //"pay/uploadDocument";
export const verifyDocument = "document/ocrVerification"; //"user/verifyDocument";
export const verifyMobileOtp = "pay/verifyMobileOtp";
export const resendOtp = "user/authenticate/sendOtp";
export const createOrder = "transaction/createOrder";
export const saveUserProfile = "pay/saveUserProfile";
export const verifyOtp = "user/authenticate/verifyOtp";
export const validateAdharOtp = "document/verifyKycOtp"; //"user/validateAdharOtp";
export const upload_document = "common/upload_document";
export const paymentSuccessful = "/user/payment-successful";
export const resetPassword = "user/authenticate/resetPassword";
export const uploadPaymentReciept = "user/uploadPaymentReciept";
export const loginViaMobile = "user/authenticate/loginViaMobile";
export const getState = "common/getStates?limit=50&country=india";
export const getTransactionSummary = "pay/getTransactionSummary";
export const resetPasswordOtpSendURL = "user/authenticate/sendOtp";
export const getUniversitiesList = "schools/getbyName?school_name";
export const getTransactionValue = "transaction/getTransactionValue";
export const deleteRecipientDocument = "transaction/deleteRecipientDocument";
export const uploadRecipientDocuments = "document/uploadTransaction"; //"transaction/uploadRecipientDocuments";
export const validateTransaction = "pay/validateTransaction";
export const docsRequired = "document/docsRequired";
export const getPaymentStatusApi="pay/getCfOrderStatus";
