export const textObj = {
  AuthSection: {
    login: {
      and: "and",
      Reset: "Reset",
      Login: "Login",
      Signup: "Signup",
      SignUp: "Sign Up",
      Confirm: "Confirm",
      Continue: "Continue",
      password: "password",
      yourEmail: "your email",
      ResendOTP: "Resend OTP",
      NewOnFly: "New on Fly?",
      indianCountryCode: "+91",
      VerifyEmail: "Verify email",
      ResendOTPin: "Resend OTP in",
      iAgreeToThe: "I agree to the",
      AlreadyAUser: "Already a user?",
      privacyPolicy: "privacy policy",
      LoginViaEmail: "Login via email",
      passwordExample: "E.g., Abcd@123",
      SetNewPassword: "Set new password",
      CheckYourEmail: "Check your email",
      ForgotPassword: "Forgot password?",
      LoginViaMobile: "Login via mobile",
      LoginToContinue: "Login to continue",
      CreateAnAccount: "Create an account",
      RememberPassword: "Remember password?",
      emailPlaceholder: "Please Enter Email",
      termsAndConditions: "terms & conditions",
      emailError: "Please enter a valid Email",
      validOtpError: "Please enter a valid OTP",
      VerifyMobileNumber: "Verify mobile number",
      validEmailError: "Please enter a valid Email",
      validNumberError: "Please enter a valid Number",
      validMobileError: "Please enter a valid Mobile",
      PasswordDoesNotMatch: "Password does not match",
      linkSentOnMail: "Link has been sent to your mail",
      validPasswordError: "Please enter a valid password",
      EnterYourRegisteredEmail: "Enter your registered email",
      enterVerificationCodeEmailToContinue:
        "Please enter the verification code sent to your email",
      enterVerificationCodeMobileToContinue:
        "Please enter the verification code sent to your mobile",
      linkExpirationMessage: "Link has expired. Please try again",
      fillDetailsWarning: "Please fill valid details in the field",
      enterVerificationText: "Please enter the verification code sent to",
      passwordDoesNotMatchCriteria: "Password does not match the criteria",
      linkToResetYourPassword: "Link to reset your password has been sent on",
      enterEmailWishToContinue: "Enter the email ID you wish to continue with",
      enterPhoneToContinue: "Enter the phone number you wish to continue with",
      passwordError:
        "Password should have minimum 8 characters & at-least one - capital letter, special character and number",
      passwordIndicator: `Password should contain atleast 8 characters in addition to:One - lower case, upper case, numeral and symbol.`,
    },
    label: {
      Email: "Email",
      Password: "Password",
      LastName: "Last Name",
      FirstName: "First Name",
      NewPassword: "New Password",
      MobileNumber: "Mobile Number",
      ConfirmPassword: "Confirm Password",
    },
    placeholder: {
      LastName: "Please Enter Last Name",
      FirstName: "Please Enter First Name",
      emailPlaceholder: "Please Enter Email",
      passwordPlaceholder: "Please Enter Password",
      enterNewPassword: "Please Enter New Password",
      MobilePlaceholder: "Please Enter Mobile Number",
      ConfirmPassword: "Please Enter Confirm Password",
    },
  },
  TopNav: {
    Avatar: "Avatar",
    Logout: "Logout",
    Amount: "Amount",
    Profile: "Profile",
    Preview: "Preview",
    Payment: "Payment",
    Remitter: "Remitter",
    Recipient: "Recipient",
    Overview: "Overview",
    Documents: "Docs",
    Summary: "Summary",
  },
  TransactionPage: {
    text: {
      Date: "Date",
      TxnID: "Txn ID",
      cancel: "cancel",
      Amount: "Amount",
      FXRate: "FX Rate",
      Actions: "Actions",
      Recipient: "Recipient",
      moreButton: "more button",
      Transactions: "Transactions",
      PaymentStatus: "Payment Status",
      gift_declaration: "gift_declaration",
      DownloadTTReceipt: "Download TT Receipt",
      transactionDetails: "transaction details",
      TotalTransactions: "Total Transactions :",
      UploadSignedA2Form: "Upload Signed A2 Form",
      UploadOtherDocument: "Upload Other Document",
      UploadPaymentReceipt: "Upload Payment Receipt",
      DownloadSignedA2Form: "Download Signed A2 Form",
      NoTransactionsDoneYet: "No transactions done yet",
      ViewTransactionDetails: "View Transaction Details",
      DownloadPaymentInvoice: "Download Payment Invoice",
      PersonalGiftOrDonation: "Personal Gift or Donation",
      ViewFilledGiftDeclaration: "View Filled Gift Declaration",
      UploadFilledGiftDeclaration: "Upload Filled Gift Declaration",
      OnceDoneHistoryWillShow: "Once done their history will show up here",
      DownloadGiftDeclarationTemplate: "Download Gift Declaration Template",
    },
    btnText: {
      SendMoney: "Send Money",
    },
    paymentStepViewTwo: {
      INR: "INR",
      Name: "Name:",
      Cancelled: "Cancelled",
      Initiated: "Initiated",
      BankName: "Bank Name :",
      ACCNumber: "A/c Number :",
      SwiftCode: "Swift Code :",
      AmountSent: "Amount Sent :",
      Relationship: "Relationship:",
      FeeAndTaxes: "Fee and Taxes :",
      TotalPayment: "Total Payment :",
      PaymentDetails: "Payment Details",
      ModeOfPayment: "Mode of Payment :",
      TransferDetails: "Transfer Details",
      PaymentReceived: "Payment Received",
      AwaitingPayment: "Awaiting Payment",
      ShowNEFTDetails: "Show NEFT Details",
      RecipientDetails: "Recipient Details",
      DownloadSwiftCopy: "Download Swift Copy",
      DateOfTransaction: "Date of Transaction :",
      CountryOfResidence: "Country of Residence :",
      TransactionCompleted: "Transaction Completed",
      RecipientBankDetails: "Recipient Bank Details",
    },
    UploadPaymentReceipt: {
      doc_name_meta: "doc_name_meta",
      SignedA2Form: "Signed A2 Form",
      transaction_id: "transaction_id",
      signed_a2_form: "signed_a2_form",
      SIGNED_A2_FORM: "SIGNED_A2_FORM",
      otherDocuments: "other-documents",
      payment_receipt: "payment_receipt",
      PAYMENT_RECEIPT_UPLOADED_BY_STUDENT:
        "PAYMENT_RECEIPT_UPLOADED_BY_STUDENT",
      UploadSignedA2Form: "Upload Signed A2 Form",
      UploadOtherDocument: "Upload Other Document",
      LOAN_SANCTIONED_LETTER_UPLOADED_BY_STUDENT:
        "LOAN_SANCTIONED_LETTER_UPLOADED_BY_STUDENT",
      FILLED_GIFT_DECLARATION_UPLOADED_BY_STUDENT:
        "FILLED_GIFT_DECLARATION_UPLOADED_BY_STUDENT",
      UploadPaymentReceipt: "Upload Payment Receipt",
      LoanSanctionedLetter: "Loan Sanctioned Letter",
      loan_sanctioned_letter: "loan_sanctioned_letter",
      UploadLoanSanctionedLetter: "Upload Loan Sanctioned Letter",
    },
  },
  MyRecipient: {
    countries: {
      italy: "italy",
      canada: "canada",
      france: "france",
      germany: "germany",
      ireland: "ireland",
      singapore: "singapore",
      australia: "australia",
      netherlands: "netherlands",
      unitedStates: "united states",
      unitedKingdom: "united kingdom",
      unitedArabEmirates: "united arab emirates",
    },
    Self: "Self",
    Save: "Save",
    Canada: "Canada",
    ACCNo: "A/C no:",
    Actions: "Actions",
    BankName: "Bank name",
    Recipient: "Recipient",
    Australia: "Australia",
    Singapore: "Singapore",
    SwiftCode: "Swift code",
    University: "University",
    ViewDetails: "View Details",
    SomeoneElse: "Someone Else",
    OfferLetter: "Offer Letter",
    OFFER_LETTER: "OFFER_LETTER",
    AddRecipient: "Add Recipient",
    PersonalGift: "Personal gift",
    UnitedStates: "United States",
    RecipientName: "Recipient name",
    UnitedKingdom: "United Kingdom",
    RentAgreement: "Rent Agreement",
    UniversityFee: "University Fee",
    LivingExpenses: "Living expenses",
    RelatedDocument: "Related document",
    RecipientAddress: "Recipient address",
    TotalRecipients: "Total Recipients :",
    FamilyMaintenance: "Family maintenance",
    RecipientPassport: "Recipient Passport",
    BankAccountNumber: "Bank account number",
    RECIPIENT_PASSPORT: "RECIPIENT_PASSPORT",
    BankAccountCountry: "Bank account country",
    RENT_AGREEMENT_FORM: "RENT_AGREEMENT_FORM",
    NoRecipientAddedYet: "No recipient added yet",
    RecipientBankDetails: "Recipient bank details",
    AccommodationLandlord: "Accommodation(Landlord)",
    UniversityAccomodation: "University Accomodation",
    PleaseEnterAValidCity: "Please enter a valid City",
    UniversityNameRequired: "University Name Required",
    RecipientContactDetails: "Recipient contact details",
    PleaseEnterAValidEmail: "Please enter a valid Email",
    PleaseEnterValidDetails: "Please enter valid details",
    PleaseEnterAValidNumber: "Please enter a valid Number",
    PleaseEnterRecipientName: "Please Enter Recipient Name",
    RENT_AGREEMENT_FORM_OTHERS: "RENT_AGREEMENT_FORM_OTHERS",
    PleaseEnterAValidAddress: "Please enter a valid Address",
    PleaseUploadValidDocument: "Please upload valid document",
    PleaseEnterAValidBSBCode: "Please enter a valid BSB Code",
    PleaseEnterAValidACHCode: "Please enter a valid ACH Code",
    PleaseEnterAValidBankName: "Please enter a valid Bank Name",
    PleaseEnterAValidIBANCode: "Please enter a valid IBAN Code",
    PleaseSelectAValidRelation: "Please select a valid relation",
    PleaseEnterAValidReference: "Please enter a valid Reference",
    PleaseEnterAValidSwiftCode: "Please enter a valid Swift code",
    PleaseEnterAValidPostalCode: "Please enter a valid Postal code",
    AccommodationServiceProvider: "Accommodation(Service Provider)",
    PleaseEnterAValidUnitNumber: "Please enter a valid Unit Number",
    BankAccountCountryIsRequired: "Bank Account Country is Required",
    OnceAddedTheyWillShowUpHere: "Once added they will show up here",
    PleaseEnterAValidTransitCode: "Please enter a valid Transit Code",
    PleaseEnterAValidAccountNumber: "Please enter a valid Account Number",
    ChooseRelationshipWithRecipient: "Choose Relationship with Recipient",
    forSecurityReasonNoEditAndRemove:
      "For security reasons, recipient details once added cannot be edited or removed later",
    PleaseEnterAValidBranchName: "Please enter a valid Branch Name",
    RecipientCountryIsRequired: "Recipient Country is Required",
  },
  SendMoneyNew: {
    Hi: "Hi",
    No: "No",
    Yes: "Yes",
    INR: "INR",
    USD: "USD",
    USA: "USA",
    inr: "inr",
    ebix: "EbixCash",
    self: "self",
    Self: "Self",
    S1301: "S1301",
    S1302: "S1302",
    S0305: "S0305",
    oneFifty: "150",
    sender: "sender",
    Amount: "Amount:",
    student: "student",
    Purpose: "Purpose:",
    YouSend: "You Send",
    moneyhop: "moneyhop",
    reciever: "reciever",
    SendingTo: "Sending to",
    University: "University",
    someoneElse: "someoneElse",
    Institution: "Institution",
    TheyReceive: "They Receive",
    pointFivePercentage: "0.5%",
    SomeoneElse: "Someone Else",
    PersonalGift: "Personal gift",
    TollFreeNumebr: "1800-572-126",
    FeesIncluded: "Fees Included =",
    UniversityFee: "University Fee",
    Note: "Note : TCS will be charged at",
    TCSNote:
      "Note: Applicable TCS  (Tax Collected at Source)  will be updated on the final rate card.",
    FamilyMaintenance: "Family maintenance",
    OverseasEducation: "Overseas Education -",
    UnitedStatesDollar: "United States Dollar",
    PurposeOfTransaction: "Purpose of transaction",
    PaymentType: "Payment type",
    LoanSanctionedLetter: "Loan Sanctioned Letter",
    OverseasEducationLoan: "Overseas Education Loan",
    LOAN_SANCTIONED_LETTER: "LOAN_SANCTIONED_LETTER",
    UniversityAccomodation: "University Accomodation",
    anyTimeStuck: "anytime you feel stuck during the transfer process.",
    feelFreeToCallOUrExpert:
      "Let’s get started! Feel free to call our expert on",
    AmountNotMoreThanTenThosendsWarning: "Amount cannot be less than Rs 10000",
    onlySeventyThousends:
      "on the amount exceeding Rs. 700000 only if you have loan disbursement letter.",
    hiPayingTo: "Hi, You are paying to the ",
    feelFreeToEmail: "Feel free to email our expert at ",
    emailTo: "support@fly.finance.com",
  },
  PaymentPage: {
    payment: {
      UPI: "UPI",
      Pay: "Pay",
      FREE: "FREE",
      YouPay: "You Pay :",
      NEFTRTGS: "NEFT/RTGS",
      TryAgain: "Try again",
      DebitCard: "Debit Card",
      DEBIT_CARD: "DEBIT_CARD",
      NetBanking: "Net Banking",
      UptoPercentage: "Upto 1.5%",
      ChargesPercentage: "Charges (%)",
      WorkingHours: "48 Working Hours",
      EstimatedCredit: "Estimated Credit",
      LastTransactionTimedOut: "Last transaction timed-out!",
      validUptoOneLakh: "Valid for up to Rs 1 lakh transaction.",
      PleaseSelectPaymentMethod: "Please select the payment method.",
      AmountUpdated: "The amount has been updated, please review once",
      SendMoneyFromNetBanking: "Send money from your internet banking.",
      validForThreeHours: `Valid for 3 hours from initiating the transfer.`,
      VisaAndMasterCardsAreSupported: "Visa and master cards are supported",
      Note: `Note: Debit card charges are levied by the payment gateway. Not by Fly Forex.`,
      makeSurePaymentFromRemitterAccount: `Please make sure that the payment is being made from the remitter’s account only otherwise the transaction might be rejected.`,
    },
    paymentSuccess: {
      MailUsAt: "Mail us at",
      GoToDASHBOARD: "GO TO DASHBOARD",
      ViewTransactions: "View Transactions",
      mailExample: "hello@fly.finance",
      SomethingWentWrong: "Sorry! Something went wrong",
      PaymentLinkExpired: "Payment link has expired",
      ContactUniversityError:
        "Please contact your university/accommodation service provider to get a fresh payment link",
      Contact: "Contact",
      orderNotFound: `Order not found or placed, Please try again.`,
      PleaseMakeSureThePaymentOf: "Please make sure the payment of",
      DocumentNotFound: `Documents not found please go back and check.`,
      ThankYouForTransactingWithUs: "Thank you for transacting with us",
      YourTransactionIsInProgress: "Your transaction is under process",
      trackYousStatus:
        "You can track the status of your transaction from your dashboard.",
      Note: "Note: Your TT receipt will be issued in less than 48m hours.",
      DocumentFailedToUpload: `Documents failed to upload please go back and check.`,
      ErrorOccuredDuringExecution: `Error occured during execution please check after sometime.`,
      InCaseOfWrongDeduction: `in case of deduction of your funds. We will be able to sort out the problem within two days`,
      TransferredAmount: `INR is transferred in the next 3 hours. You can track the status of your transaction from your dashboard.`,
    },
  },
  PaymentMethod: {
    neftRtgsModal: {
      Free: "Free",
      Step1: "Step 1  :",
      Step2: "Step 2  :",
      Step3: "Step 3  :",
      NEFTRTGS: "NEFT/RTGS",
      IFSC: "IFSC : RATN0VAAPIS",
      ChargesPercentage: "Charges (%)",
      BankName: "Bank Name : The Ratnakar Bank Limited",
      AccountNumber: "Account Number : 2223330081860748",
      LoginWithYourBankAccount: "Login with your bank account.",
      AccountName: "Account Name : Intermediary Outward Remittance",
      EstimatedCreditTime: `Estimated credit time: 48 working hours`,
      Note: `Note: Payment should be made in the next 3 working hours. Please
      make sure that the payment is being made from remitter’s account
      only otherwise the transaction might be rejected.`,
      AddABeneficiary: "Add a beneficiary with the following account details:",
      manual: `or walk to the bank do the manual, NEFT/RTGS of the exact same amount.`,
      PostPaymentUploadPaymentProof: `Post payment please upload the payment confirmation proof on the dashboard.`,
      pleaseEnterExactAmount: `Please transfer the exact amount from your account to beneficiary account once activated.`,
    },
    GST: "GST",
    TCS: "TCS*",
    BankFee: "Bank fee",
    ExchangeRate: "Exchange rate",
    TotalPayable: "Total payable",
    PayOnly: "Pay only",
    TaxesAndFees: "Taxes and fees",
    NostroCharges: "Nostro charges",
    ConvenienceFee: "Convenience fee",
    AmountConverted: "Amount converted",
    ConversionAmount: "Conversion amount",
    RecipientReceives: "Recipient receives",
    SelectPaymentMethod: "Select payment method",
    taxIndication:
      "* Applicable TCS (tax collected at source) will be displayed in the final rate card",
  },
  UserAccount: {
    userSelection: {
      moneyhop: "moneyhop",
      userType: "usertype",
      personalDetails: "personaldetails",
    },
    UserType: {
      User: "User",
      Personal: "Personal",
      Business: "Business",
      PayingFromPersonalBankAccount:
        "If paying in from your personal bank account.",
      OnBehalfOfSomeoneElse: "On behalf of someone else",
      NotAvailableAtTheMoment: "Not available at the moment.",
      PleaseChooseTypeOFUserAccount:
        "Please choose the type of user account you want to create",
      PayingFromBusinessAccount: "If paying in from your business account.",
    },
    nine: 9,
    No: "No",
    Yes: "Yes",
    Your: "Your",
    Self: "Self",
    ebix: "EbixCash",
    Father: "Father",
    Indian: "Indian",
    Upload: "Upload",
    Aadhaar: "Aadhaar",
    PanCard: "PanCard",
    toMarch: "to 31st Mar '",
    Student: "Student",
    threeHundred: "300",
    PaNCard: "Pan Card",
    Passport: "Passport",
    PAN_CARD: "PAN_CARD",
    Required: "required",
    Remitter: "Remitter",
    documents: "documents",
    MyProfile: "My Profile",
    University: "University",
    Unemployed: "Unemployed",
    Successful: "Successful",
    AADHAR_CARD: "AADHAR_CARD",
    SomeoneElse: "Someone Else",
    AadhaarCard: "Aadhaar Card",
    InvalidDate: "Invalid date",
    Aadhaar_Back: "Aadhaar_Back",
    BasicDetails: "Basic details",
    OtherDetails: "Other details",
    Aadhaar_Front: "Aadhaar_Front",
    PASSPORT_BACK: "PASSPORT_BACK",
    PassportFront: "Passport Front",
    PASSPORT_FRONT: "PASSPORT",
    DrivingLicense: "Driving License",
    AddressDetails: "Address details",
    ExcludingTaxes: "*Excluding taxes",
    DRIVING_LICENCE: "DRIVING_LICENCE",
    AADHAR_CARD_BACK: "AADHAR_CARD_BACK",
    validCity: "Please enter a valid City",
    validName: "Please enter a valid Name",
    validState: "Please enter a valid State",
    validGender: "Please enter a valid Gender",
    validAmount: "Please enter a valid amount",
    DrivingLicenseFront: "DrivingLicenseFront",
    validIfsc: "Please enter a valid ifsc code",
    DRIVING_LICENCE_FRONT: "DRIVING_LICENCE_FRONT",
    EnterRemittedAmount: "Enter remitted amount",
    DRIVING_LICENCE_BACK: "DRIVING_LICENCE_BACK",
    validAddress: "Please enter a valid Address",
    validPinCode: "Please enter a valid Pin code",
    LoanSanctionedLetter: "Loan Sanctioned Letter",
    validRelation: "Please select a valid Relation",
    minAgeSixteen: "Minimum age required (16 years)",
    LOAN_SANCTIONED_LETTER: "LOAN_SANCTIONED_LETTER",
    maxAgeOneTwenty: "Maximum age required (120 years)",
    validNationality: "Please enter a valid Nationality",
    EnterValueGreaterThanPreviousValue:
      "Entered value should be greater than previous value",
    PleaseFillValidDetails: "Please fill the valid details",
    IsTheStudentTheRemitter: "Is student the remitter?",
    validIndustryType: "Please select a valid Industry type",
    validSourceOfFunds: "Please select a valid Source of funds",
    RelationshipWithTheStudent: "Relationship with the student*",
    PleaseEnterRemitterDetails: `Please enter remitter details`,
    validEmploymentStatus: "Please select a valid Employment status",
    AreYouAPoliticallyExposedPerson: "Are you a politically exposed person?",
    haveYouRemittedMoneyInternationally:
      "Have you remitted money internationally in the current financial year (1st Apr '",

    ChoiceCanNotchanged:
      "This choice cannot be changed as you declared international transaction in the current financial year.",
    haveYouTakenALoan: `Have you taken a loan for overseas education ? If yes, then you are required to upload a loan disbursement letter.`,
    ChoiceCanNOtChangeDueTOKyc:
      "This choice cannot be changed as the KYC has been completed for the current choice. In case you need to transact with your account, please open a new account with Fly Forex.",
  },
  Documents: {
    Documents: "Documents",
    OfferLetter: "Offer Letter",
    OFFER_LETTER: "OFFER_LETTER",
    RentAgreement: "Rent Agreement",
    RENT_AGREEMENT_FORM: "RENT_AGREEMENT_FORM",
    AdditionalDocuments: "Additional Documents*",
    RemitterDocuments: "Remitter's Passport*",
    RemitterPassport : "Remitter Passport",
  },
  ReviewDetails: {
    inr: "inr",
    and: "and",
    GST: "GST",
    TCS: "TCS",
    ebix: "EbixCash",
    Nostro: "Nostro",
    Preview: "Preview",
    BankFee: "Bank fee",
    moneyHop: "moneyHop",
    BankName: "Bank name",
    IBANCode: "IBAN code",
    remittance: "remittance",
    UnitNumber: "Unit number",
    IAgreeToThe: "I agree to the",
    TotalPayable: "Total Payable",
    ExchangeRate: "Exchange rate",
    TaxesAndFees: "Taxes and fees",
    privacyPolicy: "privacy policy",
    RecipientName: "Recipient name",
    ConvenienceFee: "Convenience fee",
    AmountConverted: "Amount Converted",
    RecipientDetails: "Recipient details",
    ConversionAmount: "Conversion amount",
    RecipeintRecieves: "Recipeint recieves",
    termsAndConditions: "terms & conditions",
    BankAccountNumber: "Bank account number",
    TransactionDetails: "Transaction details",
    SwiftCodeBicCode: "Swift code / BIC code",
    BankAccountCountry: "Bank account country",
    ReferenceStudentID: "Reference / Student ID",
    PayAmountCanNotZero: "Pay Amount Can't be zero",
    agreeToTermConditions: "Kindly agree to T&C First.",
    reviewAndPay: "Please review your details and proceed to pay.",
    TaxesAndFeesWithGst: "Taxes and Fees = GST + Nostro charges + Bank Fee",
    ErrorOccurredReUploadDocuments:"Error Occurred, re-upload documents"
  },
  ProfilePersonalDetails: {
    nine: 9,
    No: "No",
    Yes: "Yes",
    Self: "Self",
    ebix: "EbixCash",
    Father: "Father",
    Indian: "Indian",
    Upload: "Upload",
    Others: "Others",
    Aadhaar: "Aadhaar",
    PanCard: "PanCard",
    toMarch: "to Mar-",
    Student: "Student",
    threeHundred: "300",
    PaNCard: "Pan Card",
    Passport: "Passport",
    PAN_CARD: "PAN_CARD",
    Remitter: "Remitter",
    documents: "documents",
    MyProfile: "My Profile",
    University: "University",
    Unemployed: "Unemployed",
    Successful: "Successful",
    AADHAR_CARD: "AADHAR_CARD",
    SomeoneElse: "Someone Else",
    AadhaarCard: "Aadhaar Card",
    InvalidDate: "Invalid date",
    Aadhaar_Back: "Aadhaar_Back",
    BasicDetails: "Basic details",
    OtherDetails: "Other Details",
    Aadhaar_Front: "Aadhaar_Front",
    PASSPORT_BACK: "PASSPORT_BACK",
    PassportFront: "Passport Front",
    PASSPORT_FRONT: "PASSPORT_FRONT",
    DrivingLicense: "DrivingLicense",
    AddressDetails: "Address Details",
    ExcludingTaxes: "*Excluding taxes",
    DRIVING_LICENCE: "DRIVING_LICENCE",
    AADHAR_CARD_BACK: "AADHAR_CARD_BACK",
    validCity: "Please enter a valid City",
    validName: "Please enter a valid Name",
    validState: "Please enter a valid State",
    validGender: "Please enter a valid Gender",
    validAmount: "Please enter a valid amount",
    DrivingLicenseFront: "DrivingLicenseFront",
    DRIVING_LICENCE_FRONT: "DRIVING_LICENCE_FRONT",
    EnterRemittedAmount: "Enter remitted amount",
    DRIVING_LICENCE_BACK: "DRIVING_LICENCE_BACK",
    validAddress: "Please enter a valid Address",
    validPinCode: "Please enter a valid Pin code",
    validDetails: "Please fill the valid details",
    LoanSanctionedLetter: "Loan Sanctioned Letter",
    minAgeSixteen: "Minimum age required (16 years)",
    LOAN_SANCTIONED_LETTER: "LOAN_SANCTIONED_LETTER",
    PleaseEnterAValidName: "Please enter a valid Name",
    PleaseEnterAValidRelationshipWithStudent:
      "Please enter a valid relationship_with_student",
    maxAgeOneTwenty: "Maximum age required (120 years)",
    validNationality: "Please enter a valid Nationality",
    minAgeSixteenYears: "Minimum age required (16 years)",
    EnterValueGreaterThanPreviousValue:
      "Entered value should be greater than previous value",
    PleaseFillValidDetails: "Please fill the valid details",
    IsTheStudentTheRemitter: "Is the student the remitter?",
    validIndustryType: "Please select a valid Industry type",
    validSourceOFFunds: "Please select a valid Source of funds",
    RelationshipWithTheStudent: "Relationship with the student*",
    PleaseEnterRemitterDetails: `Please enter remitter details`,
    validEmploymentStatus: "Please select a valid Employment status",
    AreYouAPoliticallyExposedPerson: "Are you a politically exposed person?",
    haveYouRemittedMoneyInternationally:
      "Have you remitted money internationally in the current financial year (Apr-",

    ChoiceCanNotchanged:
      "This choice cannot be changed as you declared international transaction in the current financial year.",
    haveYouTakenALoan: `Have you taken a loan for overseas education ? If yes, then you are required to upload a loan disbursement letter.`,
    ChoiceCanNOtChangeDueTOKyc:
      "This choice cannot be changed as the KYC has been completed for the current choice. In case you need to transact with your account, please open a new account with Fly Forex.",
  },
  errorPage: {
    NoServiceAvailable: "Invalid Link",
    errorFallback: "We did not expect this error",
    errorIndicator: `This link is invalid as your service provider has created a fresh link for your payment. Please check your mail and restart the transaction.`,
  },
  Modal: {
    Close: "Close",
    Confirm: "Confirm",
    viewDetailsModal: {
      City: "City",
      Name: "Name",
      Email: "Email",
      Address: "Address",
      PhoneNumber: "Phone Number",
      RecipientAddress: "Recipient Address",
      RecipientDetails: "Recipient Details",
      CountryOfResidence: "Country of Residence",
      YouWantToDeleteThis: "You want to delete this ?",
      RecipientContactDetails: "Recipient Contact Details",
    },
    confirmModal: {
      No: "No",
      Yes: "Yes",
      AreYouSure: "Are you sure?",
      YouWantToCloseTheWindow: "You want to close the window?",
    },
    timerModal: {
      DownloadSwiftCopy: "Download Swift Copy",
    },
  },
  Notification: {
    TimeOut: "Time out!",
    PleaseRetry: "Please Retry",
    SizeIsTooBig: "Size is too big",
    ThereIsSomeError: "There is some error.",
    SomethingWentWrong: "Something Went Wrong",
    PleaseUploadInDocumentInAllowedFormat:
      "Please upload in document in allowed format",
    DocsUploadedSuccessfully: "Docs Uploaded Successfully",
    ErrorOccuredDuringExecution: "Error Occured During Execution",
    FileSizeShouldNotExceedFiveMb: "File size should not exceed 5mb",
    UnableToGetTransactionData: `Unable to get transaction data. Kindly refresh and try again.`,
    KindlyGoBackAndInitiateTransactionAgain: `Kindly go back and initiate the transaction again`,
    RedirectingToPaymentGatewayPleaseWait: `Redirecting you to the payment gateway, please wait …`,
    UnableToProcessNowKindlyRefresh: `Unable to process you request at this time. Kindly refresh and try again.`,
    SometHingWrongGoBackToHomePage: `Something went wrong. Kindly go back to homepage and check the status of tansaction.`,
  },
  label: {
    City: "City",
    Email: "Email",
    State: "State",
    Gender: "Gender",
    Select: "Select",
    Address: "Address",
    PinCode: "Pin Code",
    BSBCode: "BSB Code",
    Document: "Document",
    FullName: "Full Name",
    IBANCode: "IBAN Code",
    BankName: "Bank Name",
    StudentID: "Student ID",
    PostalCode: "Postal Code",
    UnitNumber: "Unit Number",
    Nationality: "Nationality",
    TransitCode: "Transit Code",
    RecipientCountry: "Recipient Country",
    SelectState: "Select State",
    Relationship: "Relationship",
    EmailAddress: "Email address",
    DateOfBirth: "Date of birth*",
    MobileNumber: "Mobile Number",
    IndustryType: "Industry Type",
    AddressLine2: "Address Line 2",
    AddressLine1: "Address Line 1",
    RecepientName: "Recipient Name",
    SourceOfFunds: "Source of fund",
    UploadPanCard: "Upload Pan Card",
    StudentDetails: "Student Details",
    UploadDocument: "Upload Document",
    UniversityName: "University Name",
    AmountToBePaid: "Amount to be paid",
    SelectIDProof: "Select address proof",
    EmploymentStatus: "Employment Status",
    UploadOfferLetter: "Upload Offer Letter",
    BankAccountNumber: "Bank Account Number",
    UploadAadhaarBack: "Upload Aadhaar Back",
    SwiftCodeBicCode: "Swift code / BIC code",
    UploadAadhaarFront: "Upload Aadhaar Front",
    BankAccountCountry: "Bank Account Country",
    UploadPassportBack: "Upload Passport Back",
    EnterDocumentName: "Enter document name *",
    UploadPassportFront: "Upload Passport Front",
    UploadRentAgreement: "Upload Rent Agreement",
    ReferenceStudentID: "Reference / Student ID",
    FromPersonalAccount: "From personal account",
    ACHCodeRoutingNumber: "ACH Code/Routing number",
    FromEducationLoanAmount: "From education loan amount",
    UploadDrivingLicenseBack: "Upload Driving License Back",
    RelationshipWithRecipient: "Relationship with recipient",
    UploadDrivingLicenseFront: "Upload Driving License Front",
    BranchName: "Branch Name",
    BankAddress: "Bank Address",
  },
  placeholder: {
    Gender: "Select Gender",
    City: "Please Enter City",
    State: "Please Enter State",
    Email: "Please Enter Email",
    PleaseSelect: "Please Select",
    Address: "Please Enter Address",
    BSBCode: "Please Enter BSB Code",
    PinCode: "Please Enter Pin Code",
    FullName: "Please Enter Full Name",
    BankName: "Please Enter bank name",
    IBANCode: "Please Enter IBAN Code",
    BankCountry: "Please Select Country",
    IndustryType: "Select Industry Type",
    SelectIDProof: "Select address proof",
    UnitNumber: "Please Enter Unit Number",
    SourceOfFunds: "Select Source of fund",
    Relationship: "Please Select Relation",
    PostalCode: "Please Enter Postal Code",
    Nationality: "Please Enter Nationality",
    PleaseSelectState: "Please Select State",
    TransitCode: "Please Enter Transit Code",
    MobileNumber: "Please Enter Mobile Number",
    RecepientName: "Please Type Recipient Name",
    AddressLine1: "Please Enter Address Line 1",
    AddressLine2: "Please Enter Address Line 2",
    UniversityName: "Type to search university",
    EmploymentStatus: "Select Employment Status",
    BankAccountNumber: "Please Enter Bank Account Number",
    SwiftCodeBicCode: "Please Enter Swift code / BIC code",
    BankAccountCountry: "Please Select Bank Account Country",
    ReferenceStudentID: "Please Enter Reference / Student ID",
    ACHCodeRoutingNumber: "Please Enter ACH Code/Routing number",
    FromPersonalAccount: "Please enter amount from personal account",
    RelationshipWithRecipient: "Please Select relationship with recipient",
    FromEducationLoanAmount: "Please enter amount from education loan amount",
    BranchName: "Please enter branch name",
    BankAddress: "Please enter bank address" ,
  },
  btnText: {
    Save: "Save",
    Continue: "Continue",
    InPartnershipWith: "In partnership with",
  },
  UserComponents: {
    AnimationLoader: {
      and: "and",
      mins: "mins",
      secs: "secs",
      OkGotIt: "Ok, got it",
      AcceptNewRates: "Accept new rates",
      ExchangeRateAmount: "Exchange Rate: 1.00",
      ForexRateLockedForNext: "Forex rate locked for next",
      exhaustedTimeWarning:
        "You have exhausted 15 minutes, exchange rates have changed",
      CompleteYourTransactionWithin: "Complete your transaction within",
      doNotCompleteWarning:
        "In case you don't complete, you will have to restart the transaction",
      exchangeNewRateSuggestions:
        "Please accept new exchange rates in order to continue with this transaction",
    },
    UserDocUpload: {
      Upload: "Upload",
      Document: "Document",
      doc_name_meta: "doc_name_meta",
      transaction_id: "transaction_id",
      onlyImageFilesAllowed: "Only .png, .jpeg files are accepted",
      onlyImageAndPdf: "Only .png, .jpeg and .pdf files are accepted",
      PAYMENT_RECEIPT_UPLOADED_BY_STUDENT:
        "PAYMENT_RECEIPT_UPLOADED_BY_STUDENT",
      acceptedFormat: "image/png,image/jpeg,image/jpg",
      acceptedFormatImagePdf: "image/png,image/jpeg,image/jpg,application/pdf",
    },
    NoobDropDownCountry: {
      USD: "USD",
      USA: "USA",
      reciever: "reciever",
      TheyReceive: "They Receive",
      UniversityFee: "University Fee",
      UnitedStatesDollar: "United States Dollar",
    },
    AmountHeader: {
      Amount: "Amount",
      Remiter: "Remiter",
      Preview: "Preview",
      Payment: "Payment",
      TryAgain: "Try again",
      Recipient: "Recipient",
      closeWindow: "You want to close the window?",
      TransactionTimedOut: "The transaction timed-out!",
      amountUpdatedReview: "The amount has been updated, please review once",
    },
  },
  UserUploadDocuments: {
    UserDocContainer: {
      self: "self",
      Other: "Other",
      Father: "Father",
      mother: "mother",
      spouse: "spouse",
      father: "father",
      PURPOSE: "PURPOSE",
      Invoice: "Invoice",
      RECEPIENT: "RECEPIENT",
      individual: "individual",
      Individual: "Individual",
      TenantForm: "Tenant form",
      institution: "institution",
      SelectValue: "Select value",
      RELATIONSHIP: "RELATIONSHIP",
      relationship: "relationship",
      RecipientProof: "Recipient proof",
      RECEPIENT_OTHER: "RECEPIENT_OTHER",
      RECEPIENT_INVOICE: "RECEPIENT_INVOICE",
      FamilyMaintenance: "Family Maintenance",
      RentAgreementForm: "Rent agreement form",
      PleaseUploadExecutedAgreementCopy:
        "Please upload Executed Agreement Copy",
      SelectDocumentType: "Select document type",
      ProofOfRelationshipWithTheStudent:
        "Proof of relationship with the student",
      InvoiceOfPrivateAccomodationPartner:
        "Invoice of private accomodation partner",
      RECEPIENT_STUDENT_ID: "RECEPIENT_STUDENT_ID",
      PleaseUploadInvoice: "Please upload Invoice",
      LOAN_SANCTIONED_LETTER: "LOAN_SANCTIONED_LETTER",
      ExecutedAgreementCopy: "Executed agreement copy",
      RECEPIENT_OFFER_LETTER: "RECEPIENT_OFFER_LETTER",
      PersonalGiftORDonation: "Personal Gift or Donation",
      UploadRequiredDocuments: "Upload required documents",
      RECEPIENT_PASSPORT_FRONT: "RECEPIENT_PASSPORT_FRONT",
      PleaseUploadOfferLetter: "Please upload offer letter",
      PleaseUploadStudentIdIssuedByTheUniversity:
        "Please upload Student ID issued by the university",
      SelectPurposeOfTransaction: "Select purpose of transaction",
      NoAdditionalDocumentRequired: "No additional document required",
      OfferLetterFromTheUniversity: "Offer letter from the university",
      SelectRelationshipWithStudent: "Select relationship with student",
      FileExtensionSupported:
        "File extensions supported .pdf, .doc, .docx, .jpeg, .jpg, .png",
      PleaseUploadSupportingDocument: "Please upload supporting document",
      StudentIdIssuedByTheUniversity: "Student ID issued by the university",
      PleaseUploadBeneficiaryPassport: "Please upload beneficiary passport",
      RECEPIENT_EXECUTED_AGREEMENT_COPY: "RECEPIENT_EXECUTED_AGREEMENT_COPY",
      ProofForPurposeOfTheTransaction: "Proof for purpose of the transaction",
      OverseasEducationUniversityFees: "Overseas Education - University Fees",
      OverseasEducationLivingExpenses: "Overseas Education - Living Expenses",
    },
    UserDocUpload: {
      Wife: "Wife",
      S0305: "S0305",
      Spouse: "Spouse",
      Mother: "Mother",
      Sister: "Sister",
      Brother: "Brother",
      PURPOSE: "PURPOSE",
      individual: "individual",
      TenantForm: "Tenant form",
      OFFER_LETTER: "OFFER_LETTER",
      RELATIONSHIP: "RELATIONSHIP",
      spouse_passport: "spouse_passport",
      FamilyMaintenance: "Family Maintenance",
      RentAgreementForm: "Rent agreement form",
      RENT_AGREEMENT_FORM: "RENT_AGREEMENT_FORM",
      STUDENT_ADHAAR_CARD: "STUDENT_ADHAAR_CARD",
      InvoiceOfPrivateAccomodationPartner:
        "Invoice of private accomodation partner",
      INVOICE_OF_PRIVATE_ACCOMODATION_PARTNER:
        "INVOICE_OF_PRIVATE_ACCOMODATION_PARTNER",
      MARRIAGE_CERTIFICATE: "MARRIAGE_CERTIFICATE",
      PleaseUploadDocumentInAllowedFormat:
        "Please upload document in allowed format",
      STUDENT_PASSPORT_BACK: "STUDENT_PASSPORT_BACK",
      STUDENT_PASSPORT_FRONT: "STUDENT_PASSPORT_FRONT",
      RECEPIENT_PASSPORT_BACK: "RECEPIENT_PASSPORT_BACK",
      PersonalGiftOrDonation: "Personal Gift or Donation",
      PleaseUploadTenantForm: "Please upload Tenant form",
      RECEPIENT_PASSPORT_FRONT: "RECEPIENT_PASSPORT_FRONT",
      PleaseUploadOfferLetter: "Please upload offer letter",
      BENEFICIARY_PASSPORT_BACK: "BENEFICIARY_PASSPORT_BACK",
      BENEFICIARY_PASSPORT_FRONT: "BENEFICIARY_PASSPORT_FRONT",
      PleaseUploadInvoiceOfPrivateAccommodationPartner:
        "Please upload Invoice of private accommodation partner",
      PleaseUploadStudentPassport: "Please upload student passport,",
      NoAdditionalDocumentRequired: "No additional document required",
      FileSizeShouldNotExceedFiveMb: "File size should not exceed 5mb",
      PleaseUploadRecipientPassport: "Please upload recipient passport",
      PleaseUploadRentAgreementForm: "Please upload Rent agreement form",
      PleaseUploadStudentAdhaarCard: "Please upload student Adhaar card",
      PleaseSelectSupportingDocument: "Please select supporting document",
      PleaseUploadBeneficiaryPassport: "Please upload Beneficiary Passport",
      PleaseUploadMarriageCertificate: "Please upload marriage certificate",
      OverseasEducationUniversityFees: "Overseas Education - University Fees",
      OverseasEducationLivingExpenses: "Overseas Education - Living Expenses",
      STUDENT_ID: "STUDENT_ID",
      PleaseUploadStudentIdIssuedByTheUniversity:
        "Please upload Student ID issued by the university",
      OTHER: "OTHER",
      PleaseUploadSupportingDocument: "Please upload supporting document",
      TENANT_FORM: "TENANT_FORM",
      RECEPIENT_INVOICE: "RECEPIENT_INVOICE",
      RECEPIENT: "RECEPIENT",
      PleaseUploadInvoice: "Please upload Invoice",
      RECEPIENT_EXECUTED_AGREEMENT_COPY: "RECEPIENT_EXECUTED_AGREEMENT_COPY",
      PleaseUploadExecutedAgreementCopy:
        "Please upload Executed Agreement Copy",
      ExecutedAgreementCopy: "Executed agreement copy",
      RECEPIENT_STUDENT_ID: "RECEPIENT_STUDENT_ID",
      StudentIdIssuedByTheUniversity: "Student ID issued by the university",
      RECEPIENT_OFFER_LETTER: "RECEPIENT_OFFER_LETTER",
      OfferLetterFromTheUniversity: "Offer letter from the university",
      RECEPIENT_OTHER: "RECEPIENT_OTHER",
      Other: "Other",
      SelectRelationshipWithStudent: "Select relationship with student",
      DragAndDrop: "Drag and drop your file here or",
      ChooseFile: "Choose file",
      Uploading: "Uploading...",
      Uploaded: "Uploaded!",
      Front: "Front",
      upload: "upload",
      document: "document",
      UploadPassportBack: "Upload Passport Back",
      UploadPassport: "Upload Passport",
    },
  },
  redirectToAppFly:{
    ErrorOccurredRedirectingToAppFly:'Session ended, redirecting to App Fly'
  }
};
