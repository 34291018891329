import axios from "axios";
import {
  financePath,
  financePathV1,
  integrationPath,
  info_platform_api,
} from "../../constants/defaultValues";
import { getState, getCountriesList } from "../api-urls";
import * as loggedInUser from "../../helpers/AuthService";
import { userNotification } from "../../element/UserNotification";
import * as storeData from "../../helpers/StoreData";
import { redirectToAppFly } from "../../helpers/Utils";

export function getApiAsync(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      await axios
        .request({
          method: "get",
          url:
            (payload.integrationPath
              ? integrationPath
              : payload.info_platform_api
              ? info_platform_api
              : financePath) + payload.url,
        })
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.meta.code === 401) {
            const transaction_data = storeData?.getTransactionData();
            if (transaction_data.source === 'FLY') {
              redirectToAppFly()
            }
            else {
              window.location.replace("/payment/link-expired");
            }
          }
          resolve(data);
          // userNotification({
          //   message: data.meta.Message,
          //   placement: "topRight",
          //   description: ``,
          //   type: "success",
          // });
        })
        .catch(function (error) {
          reject(error);
          if (error?.response?.status === 401) {
            const transaction_data = storeData?.getTransactionData();
            if (transaction_data.source === 'FLY') {
              redirectToAppFly()
            }
            else {
              userNotification({
                type: "error",
                placement: "topRight",
                message: "Error Occurred",
                description: error?.response?.data?.Message || error?.message,
                bgColor:"#E74C3C",
              });
              window.location.replace("/payment/link-expired");
            }
          }
        });
    } catch (error) {
      reject(error);
      userNotification({
        type: "error",
        placement: "topRight",
        message: "Error Occurred",
        description: error?.response?.data?.Message || error?.message,
        bgColor:"#E74C3C",
      });
    }
  });
}

export function postApiAsync(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      await axios
        .request({
          method: "post",
          url:
            (payload.integrationPath ? integrationPath : financePath) +
            payload.url,
          data: payload.data,
        })
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.meta.code === 401) {
            const transaction_data = storeData?.getTransactionData();
            if (transaction_data.source === 'FLY') {
              redirectToAppFly()
            }
            else {
              window.location.replace("/payment/link-expired");
            }
          }
          resolve(data);
          if (data.meta.code === 200) {
            resolve(data);
            userNotification({
              message: data.meta.Message,
              placement: "topRight",
              description: ``,
              type: "success",
              bgColor: "#5b6aa6",
            });
          } else {
            userNotification({
              message: data.meta.Message,
              placement: "topRight",
              description: ``,
              type: "warning",
              bgColor: "#a88116",
            });
          }
        })
        .catch(function (error) {
          reject(error);
          if (error?.response?.status === 401) {
            const transaction_data = storeData?.getTransactionData();
            if (transaction_data.source === 'FLY') {
              redirectToAppFly()
            }
            else {
              userNotification({
                type: "error",
                placement: "topRight",
                message: "Error Occurred",
                description: error?.response?.data?.Message || error?.message,
                bgColor:"#E74C3C",
              });
              window.location.replace("/payment/link-expired");
            }
          }
        });
    } catch (error) {
      reject(error);
      userNotification({
        type: "error",
        placement: "topRight",
        message: "Error Occurred",
        description: error?.response?.data?.Message || error?.message,
        bgColor:"#E74C3C",
      });
    }
  });
}

export function getApiAsyncWithHeaders(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      await axios
        .request({
          method: "get",
          url:
            (payload.integrationPath
              ? integrationPath
              : payload.info_platform_api
              ? info_platform_api
              : financePath) + payload.url,
          headers: { Authorization: loggedInUser.getToken() },
        })
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          // userNotification({
          //   message: data.meta.Message,
          //   placement: "topRight",
          //   description: ``,
          //   type: "success",
          // });
          if (data.meta.code === 401) {
            const transaction_data = storeData?.getTransactionData();
            if (transaction_data.source === 'FLY') {
              redirectToAppFly()
            }
            else {
              window.location.replace("/payment/link-expired");
            }
          }

          resolve(data);
        })
        .catch(function (error) {
          if (error?.response?.status === 401) {
            const transaction_data = storeData?.getTransactionData();
            if (transaction_data.source === 'FLY') {
              redirectToAppFly()
            }
            else {
              userNotification({
                type: "error",
                placement: "topRight",
                message: "Error Occurred",
                description: error?.response?.data?.Message || error?.message,
                bgColor:"#E74C3C",
              });
              window.location.replace("/payment/link-expired");
            }
          }
          reject(error);
        });
    } catch (error) {
      userNotification({
        type: "error",
        placement: "topRight",
        message: "Error Occurred",
        description: error?.response?.data?.Message || error?.message,
        bgColor:"#E74C3C",
      });
      reject(error);
    }
  });
}

export function postApiAsyncWithHeaders(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      await axios
        .request({
          method: "post",
          url:
            (payload.integrationPath
              ? integrationPath
              : payload.v1Path
              ? financePathV1
              : financePath) + payload.url,
          headers: { Authorization: payload?.token || loggedInUser.getToken() },
          data: payload.data,
        })
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.meta.code === 401) {
            const transaction_data = storeData?.getTransactionData();
            if (transaction_data.source === 'FLY') {
              redirectToAppFly()
            }
            else {
              window.location.replace("/payment/link-expired");
            }
          }
          if(payload.showNotification && data.meta.code !== 200){
            userNotification({
              type: "error",
              placement: "topRight",
              message: data?.meta?.Message || data?.meta?.message,
              description: ``,
              bgColor:"#E74C3C",
            });
          }
          // if (payload.showNotification) {
          //   userNotification({
          //     type: "success",
          //     description: ``,
          //     placement: "topRight",
          //     message: payload.showCustomNotification
          //       ? payload.showCustomNotification
          //       : data.meta.Message,
          //     bgColor: "#5b6aa6",
          //   });
          // }
          resolve(data);
        })
        .catch(function (error) {
          if (error?.response?.status === 401) {
            const transaction_data = storeData?.getTransactionData();
            if (transaction_data.source === 'FLY') {
              redirectToAppFly()
            }
            else {
              userNotification({
                type: "error",
                placement: "topRight",
                message: "Error Occurred",
                description: error?.response?.data?.Message || error?.message,
                bgColor:"#E74C3C",
              });
              window.location.replace("/payment/link-expired");
            }
          }
          reject(error);
        });
    } catch (error) {
      userNotification({
        type: "error",
        placement: "topRight",
        message: "Error Occurred",
        description: error?.response?.data?.Message || error?.message,
        bgColor:"#E74C3C",
      });
      reject(error);
    }
  });
}

export const getStates = async () => {
  const response = await getApiAsync({
    url: getState,
    info_platform_api: true,
  });
  if (response.meta.code === 200) {
    return response;
  }

  setTimeout(() => {
    this.setState({ loading: false });
  }, 1000);
};

export const getCountries = async () => {
  const response = await getApiAsync({
    url: getCountriesList,
    info_platform_api: true,
  });
  if (response.meta.code === 200) {
    return response;
  }

  setTimeout(() => {
    this.setState({ loading: false });
  }, 1000);
};

export const getBase64 = (file) => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => res(reader.result);
    reader.onerror = (err) => rej(err);
  });
};
