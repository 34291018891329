import { notification } from "antd";

export const userNotification = ({
  message,
  placement,
  description,
  type,
  bgColor,
}) => {
  console.log("type", type);
  notification[type]({
    message,
    placement,
    description,
    style: { backgroundColor: bgColor },
  });
};
